import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
import {  useParams } from "react-router-dom";
import api from "../apiConfig";
import {
  initialState,
  setFilterProperty,
  setParameters,
} from "../features/uisettingsSlice";
// import { initialState as backendInitialState } from "../features/backendSettingsSlice";
import toast from "react-hot-toast";
import { setChanges } from "../features/changeSlice";
import { t } from "i18next";

/**
 * Hook for getting all GenAI tiles stored in parameterstore and modifying it.
 * @returns {Object} - All tiles from parameterstore.
 * - tiles: {Array} All tiles
 * - currentTile: {object} The current tile shown in the UI, and a new state if creating a new one
 * - error: {object} The error if an error occured while fetching
 * - isLoading: {boolean} Loading state
 * - isError: {boolean} If an error occured while fetching
 */
export default function useGenAITiles() {
  /** All tiles  */
  const tiles = useSelector((state) => state.uisettings.parameters);
  const lang = useSelector((state) => state.lang.queriesLang);
  const [fetchedParameters, setFetchedParameters] = useState([]);
  const [noFilter, setNoFilter] = useState([]);
  const [enFilter, setEnFilter] = useState([]);

  const mounted = useRef(false);
  // const navigate = useNavigate();

  /** Loading state of the tiles */
  const [isLoading, setIsLoading] = useState(true);
  /** Whether an error occured while fetching the tiles */
  const [isError, setIsError] = useState(false);
  /** If an error occured while fetching the tiles, this is the error */
  const [error, setError] = useState(undefined);

  const params = useParams();
  const dispatch = useDispatch();

  /** The index of the current tile in the UI in parameter store  */
  const tileIndex = useMemo(() => {
    if (tiles.length === 0) return;
    if (params.tile === "new") return tiles.length - 1;
    const index = tiles.findIndex((tile) => tile.key === params.tile);
    return index;
  }, [tiles, params]);

  /** The current tile in the UI */
  const currentTile = useMemo(() => {
    if (tiles.length === 0) return;
    return tiles[tileIndex];
  }, [tiles, tileIndex, params]);

  useEffect(() => {
    if (mounted.current === true && currentTile) {
      if (currentTile.key !== currentTile.value) {
        const actionPayload = {
          index: tileIndex,
          property: "value",
          value: currentTile.key,
        };
        dispatch(setFilterProperty(actionPayload));
      }
    } else {
      mounted.current = true;
    }
  }, [currentTile]);

  useEffect(() => {
    setIsLoading(true);
    if (params.tile === "new") {
      api
      .get(`genai/filters`, {
        params: {
          lang: "no",
        },
      })
      .then((res) => {
        setNoFilter(res.data.body.parameters);
        setFetchedParameters(res.data.body.parameters);

        return dispatch(
          setParameters([
            ...res.data.body.parameters,
            initialState.parameters[0],
          ])
        );
      })
      .catch((err) => {
        console.error(err);
        setIsError(true);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

      api
      .get(`genai/filters`, {
        params: {
          lang: "en",
        },
      })
      .then((res) => {
        setEnFilter(res.data.body.parameters);
        setFetchedParameters(res.data.body.parameters);

        return dispatch(
          setParameters([
            ...res.data.body.parameters,
            initialState.parameters[0],
          ])
        );
      })
      .catch((err) => {
        console.error(err);
        setIsError(true);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    
    }else{

    api
      .get(`genai/filters`, {
        params: {
          lang: lang,
        },
      })
      .then((res) => {
        setFetchedParameters(res.data.body.parameters);
        if (params.tile === "new") {
          return dispatch(
            setParameters([
              ...res.data.body.parameters,
              initialState.parameters[0],
            ])
          );
        }
        return dispatch(setParameters(res.data.body.parameters));
      })
      .catch((err) => {
        // toast.error("Unable to load tiles");
        console.error(err);
        setIsError(true);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [params, lang]);

  /**
   * Changes a property of a filter to the current tile
   * @param {string} property
   * @param {string} value
   */
  function handleFilterPropertyChange(property, value) {
    const actionPayload = {
      index: tileIndex,
      property,
      value,
    };
    dispatch(setFilterProperty(actionPayload));
  }

  function saveFilterChanges() {
    api
      .post(
        `${params.bot}/filters`,
        { parameters: tiles },
        { params: { lang: lang } }
      )
      .then(() => {
        toast.success(t("common.DataUpdated"));
        dispatch(setChanges(false));
      })
      .catch(() => {
        toast.error(t("common.ErrorData"));
      });
  }

  async function createNewFilter() {
    const lastItem = tiles[tiles.length - 1]
    enFilter.push(lastItem);
    noFilter.push(lastItem);

    async function postFilters(params) {
      for (const lang of ["en", "no"]) {
        await api.post(
          `${params.bot}/filters`,
          { parameters:lang==="en" ?enFilter:noFilter },
          { params: { lang: lang } }
        );
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    }

    await postFilters(params, tiles);
    dispatch(setChanges(false));
    // api
    //   .post(
    //     "/appconfig",
    //     {
    //       key: currentTile.key,
    //       backendSettings: backendInitialState.value,
    //       isNewKey: params.tile === "new",
    //       replaceAll: false,
    //     },
    //     {
    //       params: {
    //         lang: lang,
    //       },
    //     }
    //   )
    //   .then(() => {
    //     toast.success(t("common.DataUpdated"));
    //     navigate(`/bots/genai/${currentTile.key}`, {
    //       replace: true,
    //     });
    //     dispatch(setChanges(false));
    //   });
  }

  return {
    tiles,
    currentTile,
    isLoading,
    isError,
    error,
    handleFilterPropertyChange,
    fetchedParameters,
    setIsLoading,
    saveFilterChanges,
    createNewFilter,
    lang,
  };
}
